.mainContainer {
    padding-left: 20px;
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-right: 20px;
}

.my-select-container .ant-select .ant-select-selector {
    border-radius: 16px;
}

.active-tab {
    border-right: solid #1b70f7;
    background-color: #eef6fd;
    color: #1b70f7;

}

p.titulo {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    letter-spacing: 0px;
    word-spacing: 2px;
    color: #5fa1c4;
    font-weight: 400;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    text-transform: none;
}

h3 {
    /* color: #5fa1c4;
    /* color: #5fa1c4; */
    font-family: 'Poppins';
    /* color: #007bff; */
    color: #0d6efd;
    font-style: normal;
    font-weight: 500;
}

h4 {
    color: #5fa1c4;
    /* font-family: 'Poppins'; */
    /* font-weight: 700; */
    /* color: #007bff; */
    /* color: #0d6efd; */
    font-style: normal;
    font-weight: 400;
}

h5 {
    color: #515a6e;
}

.spacedTitle {
    margin: 1rem auto;
}

.btnIconCentered {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.textArea {
    word-wrap: normal;
    text-align: left;
    white-space: pre-line
}

.fila {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.columna {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 10px;
}

.lcolumna {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 10px;
}

.sizedIcon {
    font-size: 10px;
}

/* For Detalles Paciente */
.columnWithDescriptions {
    line-height: 200%;
    /* background-color: #515a6e; */
}

.nombre {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 550;
    /* color: #5B6C79; */
    color: #515a6e;
    /* display: flex;
    align-items: center; */
}

.datos {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #515a6e;
    /* display: flex;
    align-items: center; */
}

.desc {
    font-size: 14px;
    font-weight: 500;
    color: #515a6e;
}

.subdesc {
    font-size: 12px;
    font-weight: 400;
    color: #515a6e;
}

.smallText {
    font-size: 12px;
    font-weight: 400;
    color: #515a6e;
}

/* End Of Detalles Paciente */
.dynamic-delete-button {
    position: relative;
    /* top: 2px; */
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Table */
.borderedTable {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px
}

.ant-table-thead .ant-table-cell {
    background-color: green;
}

.pie-chart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.pie-segment {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(50% 50%, 100% 100%, 100% 0);
    transform-origin: bottom right;
}