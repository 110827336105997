.pain-zone-selector {
  text-align: center;
}

.canvas-container {
  position: relative;
  display: inline-block;
}

.background-image {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  width: 600px;
  height: 625px;
  z-index: -1;
}

canvas {
  border: 1px solid #000;
}

.background-image-modal {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1; */
}

.drawing-canvas {
  /* Canvas inside modal */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.button-container {
  margin-top: 10px;
}

Button {
  margin: 0 5px;
}